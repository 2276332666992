import React from "react";

function Error(){
    return (
        <div>
            <h2>Page not found</h2>
        </div>
    )
}

export default Error